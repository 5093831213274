import React, {useEffect} from "react"
import Layout from "./../components/layout";
import styled from "styled-components";
import KidsImage from "./../images/100.jpg";
import KidsImage2 from "./../images/101.jpg";
// import video from "./../images/coverr-bg.mp4";
import ImageXBg from "./../images/x-bg.webp";
import COLORS from "../styles/colors";
import SEO from "../components/seo";

import Image1 from "./../images/florida-council-conference.png"
import Image2 from "./../images/florida-social-studies-conference.png"
import Image3 from "./../images/georgia-social-studies-conference.png"
import Image4 from "./../images/michigan-conference.png"
import Image5 from "./../images/minnesota-conference.png"
import Image6 from "./../images/national-council-conference.png"
import Image7 from "./../images/north-carolina-conference.png"
import Image8 from "./../images/nssa-conference.png"
import Image9 from "./../images/nyscss-conference.png"
import Image10 from "./../images/south-carolina-conference.png"
import Image11 from "./../images/stat-conference.png"
import Image12 from "./../images/tekscon.png"
import Image13 from "./../images/tennesee-conference.png"
import Image14 from "./../images/texas-bilingual-conference.png"
import Image15 from "./../images/texas-conference.png"
import Image16 from "./../images/tssa-conference.png"
import Image17 from "./../images/colorado-conference.png"
import Image18 from "./../images/msssa-conference.png"
import Image19 from "./../images/rrvb-conference.png";

import Slide1 from "./../images/slide-1.png"
import Slide2 from "./../images/slide-2.png"
import Slide3 from "./../images/slide-3.png"
import Slide4 from "./../images/slide-4.png"
import Slide5 from "./../images/slide-5.png"
import Slide6 from "./../images/slide-6.png"

const ChangingImage = styled.div`
    transition: background-image 1s ease-in-out;
    min-height: 500px;
`;

// const Video = styled.video`
//         margin: 0 0 -10px;
//         padding: 0;
//         height: 100%;
//         width: 100%;
//         object-fit: cover;
//         z-index: -99;
//         right: 0;
//         bottom: 0;
//         background: url(../img/index-image.jpg) no-repeat center center;
//         background-size: cover;
//         overflow: hidden;
//         max-height: 500px;
// `;


const IntroContainer = styled.div`
    display: grid;
    grid-template-areas: "one two three four";
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    min-height: 500px;

    @media screen and (max-width: 1500px){
        grid-template-areas: "one two"
                             "four three";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }

    @media screen and (max-width: 800px){
        grid-template-areas: "one"
                             "two"
                             "three"
                             "four";
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
`;

const IntroPanel = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IntroLeft = styled(IntroPanel)`
    grid-area: "two";
    background: linear-gradient(
      rgba(53, 110, 216, 0.8), 
      rgba(53, 110, 216, 0.8)
    ),
    url(${KidsImage});
    background-position: center center;
    background-size: cover;

    h1 {
        color: #fff;
        font-weight: 300;
        line-height: 2.5rem;
        font-size: 2.2rem;
    }
`;

const IntroRight = styled(IntroLeft)`
    grid-area: "three";
    background: linear-gradient(
      rgba(53, 110, 216, 0.8), 
      rgba(53, 110, 216, 0.8)
    ),
    url(${KidsImage2});
`;


const WorkshopPlug = styled.div`
    background-image: url(${ImageXBg});
    padding: 0 1rem 3rem;
`;

const WorkshopContainer = styled.div`
    max-width: 960px;
    margin: 0 auto;
    padding: 3rem 1rem 1rem;
`;

const SchoolDistrict = styled.div`
    background-color: #fff;
    margin: 1rem auto;
    padding: 0.5rem 2rem;
    width: 80%;
    max-width: 800px;
    box-shadow: 3px 4px 8px #aaa;
    transition: all 0.3s;

    &:hover {
        transform: scale(1.025);
    }

    h3 {
        margin-bottom: 0;
    }

    p {
        margin: 1rem 0 0;
        font-weight: 300;
        font-style: italic;
    }
    
    span {
        font-size: 2rem;
        font-weight: 900;
        display: inline-block;
        padding-right: 0.4rem;
        vertical-align: bottom;
    }
`;

const SchoolDistrictPerson = styled.div`
    font-weight: 300;
    font-style: italic;
    color: #aaa;
    text-align: right;
    margin-bottom: 1rem;
`;

const NumberShow = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width: 800px){
        grid-template-columns: 1fr;
    }
`;

const Counter = styled.div`
    padding: 3rem 1rem; 
    color: ${COLORS.primaryColor};
    text-align: center;

    @media screen and (max-width: 800px){
        padding: 1rem 3rem;
    }

    h3 {
        color: #222;
    }

    h1 {
        font-family: aleo;
        font-size: 5rem;
        margin: 2rem 0;

        @media screen and (max-width: 800px){
            font-size: 4rem;
        }
    }
`;

const NumberShowWrapper = styled.div`
    padding: 3rem 1rem;
    text-align: center;
`;

const NumberShowContainer = styled.div`
    max-width: 960px;
    margin: 0 auto;
`;

const ConferenceWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    padding: 3rem 1rem;
`;

const ConferenceContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`;

const Conference = styled.div`
    width: 3.5rem;
    margin: 1rem;

    img {
        display: block;
        height: auto;
        max-width: 100%;
    }
`;

const VideoContainer = styled.div`
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    height: 480px;

    @media screen and (max-width: 800px) {
        height: 320px;
    }
`;

const WhatContainer = styled.div`
    background-image: url(${ImageXBg});
    padding: 3rem 1rem;
    text-align: center;
    h1 {
        margin-top: 0;
        margin-bottom: 2rem;
    }
`;


const ExpertisePage = () => {
    const bgImageArray = [Slide2, Slide3, Slide4, Slide5, Slide1, Slide6];

    useEffect(() => {
        let k = 1;
        const intervalId = setInterval(() => {
            document.getElementById("image-bg").style.backgroundImage = `url(${bgImageArray[k]})`;
            document.getElementById("image-bg").style.backgroundSize = "cover";
            k = k === bgImageArray.length - 1 ? 0 : k + 1;
        }, 5000);
        return () => clearInterval(intervalId);
    });
    return (
        <Layout>
            <SEO title="Expertise" />
            {/* <Video playsinline autoPlay muted loop id="myVideo"><source src={video} type="video/mp4" /></Video> */}
            <ChangingImage id="image-bg"  style={{ backgroundSize: "cover", backgroundImage: `url(${Slide2})`}} />
            <IntroContainer>
                <IntroLeft>
                    <h1>Theory-Based Strategies</h1>
                </IntroLeft>
                <IntroPanel style={{padding: "5%", gridArea: "two"}}>
                    <div>
                        <h1>We know what works.</h1>
                        <p>s3strategies’ team knows what works! Years of experience and expertise transfer to workshops that model quality instruction which takes educational theory to practical, doable application.</p>
                    </div>
                </IntroPanel>
                <IntroRight>
                    <h1>Practical Application</h1>
                </IntroRight>
                <IntroPanel style={{padding: "5%", gridArea: "four"}}>
                    <div>
                        <h1>Aligned to your needs.</h1>
                        <p>Each workshop is aligned to you – your audience and your state standards. You are the most important piece of the puzzle! We customize exciting workshops and develop new ones to meet your objectives and needs.</p>
                    </div>
                </IntroPanel>
            </IntroContainer>
            <ConferenceWrapper>
                <h1>Professional Conferences</h1>
                <p>s3strategies conference sessions have received rave reviews at regional, state and national levels. Contact us to present at your next conference.</p>
                <ConferenceContainer>
                    <Conference style={{width: "7rem"}}><img src={Image1} alt="conference-1" /></Conference>
                    <Conference><img src={Image2} alt="conference-2" /></Conference>
                    <Conference><img src={Image3} alt="conference-3" /></Conference>
                    <Conference style={{width: "6rem"}}><img src={Image4} alt="conference-4" /></Conference>
                    <Conference style={{width: "3.5rem"}}><img src={Image5} alt="conference-5" /></Conference>
                    <Conference style={{width: "5rem"}}><img src={Image6} alt="conference-6" /></Conference>
                    <Conference><img src={Image7} alt="conference-7" /></Conference>
                    <Conference style={{width: "6rem"}}><img src={Image8} alt="conference-8" /></Conference>
                    <Conference style={{width: "10rem"}}><img src={Image9} alt="conference-9" /></Conference>
                    <Conference><img src={Image10} alt="conference-10" /></Conference>
                    <Conference style={{width: "10rem"}}><img src={Image11} alt="conference-11" /></Conference>
                    <Conference><img src={Image12} alt="conference-12" /></Conference>
                    <Conference style={{width: "7rem"}}><img src={Image13} alt="conference-13" /></Conference>
                    <Conference><img src={Image14} alt="conference-14" /></Conference>
                    <Conference><img src={Image15} alt="conference-15" /></Conference>
                    <Conference><img src={Image16} alt="conference-16" /></Conference>
                    <Conference style={{width: "7rem"}}><img src={Image17} alt="conference-17" /></Conference>
                    <Conference><img src={Image18} alt="conference-18" /></Conference>
                    <Conference style={{width: "6rem"}}><img src={Image19} alt="conference-19" /></Conference>
                </ConferenceContainer>
            </ConferenceWrapper>
            <WorkshopPlug>
                <WorkshopContainer>
                    <h1>Let our customers tell you.</h1>
                    <p>From some of the largest, to some of the smallest and every size district in-between, s3strategies has partnered with each to support their objectives and initiatives.</p>
                </WorkshopContainer>
                <SchoolDistrict>
                    <h3>Teachers absolutely love attending these engaging workshops</h3>
                    <p><span>"</span>We have been working with s3strategies for the past 3 years. They have provided excellent trainings for our summer and fall professional development! Our district focuses on Academic Vocabulary in all content areas and s3strategies has helped build on that initiative. They have done a fabulous job in training and helping our teachers develop and implement various activities in their classrooms. The teachers absolutely love attending these engaging workshops and take the activities immediately to their classrooms. We will continue to partner up with s3strategies to offer professional development for all our teachers.<span>"</span></p>
                    <SchoolDistrictPerson>Araceli de la Cruz, Bilingual/ESL Supervisor Edinburg CISD</SchoolDistrictPerson>
                </SchoolDistrict>
                <SchoolDistrict>
                    <h3>I could not start the year off without s3strategies</h3>
                    <p><span>"</span>s3strategies professional development sessions make my back to school professional learning days a success - I could not start the year off without s3strategies! There is something for all of my social studies teachers - from STAAR and EOC prep, to reading, writing, and critical thinking skills, to interactive note booking, vocabulary strategies, and primary source analysis.  They even have support for ELs and Advanced Learners.  They truly provide everything my teachers need to help their students be successful!<span>"</span></p>
                    <SchoolDistrictPerson>Clarissa Abbott, Secondary Social Studies Coordinator, McAllen ISD</SchoolDistrictPerson>
                </SchoolDistrict>
                <SchoolDistrict>
                    <h3>I always learn something new at their workshops</h3>
                    <p><span>"</span>s3strategies provides professional learning that is interactive, engaging, and just plain fun!  I always learn something new at their workshops.  Just as important, their workshops are based on research and provide teachers with easy to implement strategies to boost student learning.<span>"</span></p>
                    <SchoolDistrictPerson>Nicole Michener, Social Studies Coordinator, Plano ISD</SchoolDistrictPerson>
                </SchoolDistrict>
            </WorkshopPlug>
            {/* <NumberShowWrapper>
                <h1>By the Numbers.</h1>
                <NumberShowContainer>We provide high impact learner centered professional development for educators. We specialize in providing strategies and techniques designed to take brain research from the theory to practical application.</NumberShowContainer>
                <NumberShow>
                    <Counter>
                        <h3>Workshops Delivered</h3>
                        <h1>2,289</h1>
                    </Counter>
                    <Counter>
                        <h3>Professionals Helped</h3>
                        <h1>114,450</h1>
                    </Counter>
                    <Counter>
                        <h3>Repeat Customers</h3>
                        <h1>98.2<small>%</small></h1>
                    </Counter>
                </NumberShow>
            </NumberShowWrapper> */}
            <img src={Slide1} alt="placeholder-1" style={{height: "1px", width: "1px"}} />
            <img src={Slide2} alt="placeholder-2" style={{height: "1px", width: "1px"}} />
            <img src={Slide3} alt="placeholder-3" style={{height: "1px", width: "1px"}} />
            <img src={Slide4} alt="placeholder-4" style={{height: "1px", width: "1px"}} />
            <img src={Slide5} alt="placeholder-5" style={{height: "1px", width: "1px"}} />
            <img src={Slide6} alt="placeholder-6" style={{height: "1px", width: "1px"}} />
        </Layout>
    )
}
export default ExpertisePage